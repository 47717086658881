import axios from 'axios'
import Cookies from 'js-cookie'

export function getCookie(name) {
  let cookie = Cookies.get(name)

  if (cookie) {
    return cookie
  }

  return null
}

export async function requestGet(url, params) {
  const token = getCookie('jwt')

  if (params === undefined) {
    params = {}
  }

  try {
    const result = await axios.get(url, params, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return result
  } catch (error) {
    return null
  }
}

export async function requestPost(url, params, headers) {
  const token = getCookie('jwt')

  if (params === undefined) {
    params = {}
  }

  try {
    const result = await axios.post(url, params, {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    })

    return result
  } catch (error) {
    return null
  }
}
