import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cibMailRu } from '@coreui/icons'
import { requestPost } from '../../../request.js'

const ForgotPassword = () => {
  const [send, setSend] = useState(false)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const checkLogin = async () => {
    try {
      const result = await requestPost(`${process.env.REACT_APP_BACKEND}/is-login`)

      if (result.data.success === true) {
        window.location.href = '/#/dashboard'
      }
    } catch (error) {
      return
    }
  }

  useEffect(() => {
    checkLogin()
  }, [])

  const handleSend = async () => {
    if (loading) {
      return
    }
    setLoading(true)

    try {
      const result = await requestPost(`${process.env.REACT_APP_BACKEND}/forgot-password`, {
        email: email,
      })

      if (!result.data.success) {
        setErrorMsg(result.data.message)
        setLoading(false)
        return
      }

      setSend(true)
    } catch (error) {
      setLoading(false)
      return
    }

    setLoading(false)
  }

  return (
    <>
      <div className="bg-body-tertiary d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center mx-auto">
            <CCol md={8}>
              <img
                className="d-flex mx-auto"
                style={{ marginTop: '20vh' }}
                src={process.env.PUBLIC_URL + '/if-logo-dark.png'}
              />
            </CCol>
          </CRow>
        </CContainer>
      </div>
      <div
        style={{ marginTop: '4vh' }}
        className="bg-body-tertiary d-flex flex-row align-items-center"
      >
        {!send && (
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md={8}>
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <CForm>
                        <h1>Forgot your password?</h1>
                        <p className="text-body-secondary">
                          Enter your email address in order to reset password.
                        </p>
                        <CInputGroup className="mb-3">
                          <CInputGroupText>
                            <CIcon icon={cibMailRu} />
                          </CInputGroupText>
                          <CFormInput
                            placeholder="Email"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </CInputGroup>
                        {errorMsg.length > 0 && <p className="error-msg">{errorMsg}</p>}
                        <CRow>
                          <CCol xs={6}>
                            <CButton
                              style={{ width: '100%' }}
                              onClick={handleSend}
                              color="primary"
                              className="px-4"
                            >
                              Send
                            </CButton>
                          </CCol>
                          <CCol xs={6}>
                            <Link to="/login">
                              <CButton style={{ width: '100%', color: 'white' }} color="secondary">
                                Login
                              </CButton>
                            </Link>
                          </CCol>
                        </CRow>
                      </CForm>
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        )}
        {send && (
          <CContainer>
            <CRow className="justify-content-center">
              <CCol md={8}>
                <CCardGroup>
                  <CCard className="p-4">
                    <CCardBody>
                      <h1>Check Your Inbox</h1>
                      <p className="text-body-secondary">
                        We have sent a password reset link to your email. Please check your inbox
                        and follow the instructions to reset your password.
                      </p>
                      <p className="text-muted">
                        If you don’t see the email within a few minutes, please check your spam or
                        junk folder.
                      </p>
                      <CRow>
                        <CCol xs={12}>
                          <Link to="/login">
                            <CButton style={{ width: '100%', color: 'white' }} color="primary">
                              Back to Login
                            </CButton>
                          </Link>
                        </CCol>
                      </CRow>
                    </CCardBody>
                  </CCard>
                </CCardGroup>
              </CCol>
            </CRow>
          </CContainer>
        )}
      </div>
    </>
  )
}

export default ForgotPassword
