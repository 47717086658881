import React, { useState } from 'react'
import {
  CContainer,
  CRow,
  CCol,
  CCardGroup,
  CCard,
  CCardBody,
  CForm,
  CFormInput,
  CButton,
} from '@coreui/react'
import { useParams, Link } from 'react-router-dom'
import { requestPost } from '../../../request.js'

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [loading, setLoading] = useState(false)

  let { token } = useParams()

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setErrorMsg('Passwords do not match. Please try again.')
      return
    }

    if (loading) {
      return
    }

    setLoading(true)

    try {
      const result = await requestPost(`${process.env.REACT_APP_BACKEND}/reset-password`, {
        token: token,
        password: newPassword,
      })

      if (!result.data.success) {
        setErrorMsg(result.data.message)
        setLoading(false)
        return
      }

      setLoading(false)
      setSuccessMsg('Password changed successfully.')
    } catch (error) {
      setLoading(false)
      return
    }

    setLoading(false)
  }

  return (
    <>
      <div className="bg-body-tertiary d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center mx-auto">
            <CCol md={8}>
              <img
                className="d-flex mx-auto"
                style={{ marginTop: '20vh' }}
                src={process.env.PUBLIC_URL + '/if-logo-dark.png'}
              />
            </CCol>
          </CRow>
        </CContainer>
      </div>
      <div
        style={{ marginTop: '4vh' }}
        className="bg-body-tertiary d-flex flex-row align-items-center"
      >
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={8}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <h1>Change Password</h1>
                    <p className="text-body-secondary">Enter your new password below.</p>
                    <CForm>
                      <CFormInput
                        type="password"
                        placeholder="New Password"
                        autoComplete="new-password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className="mb-3"
                      />
                      <CFormInput
                        type="password"
                        placeholder="Confirm New Password"
                        autoComplete="new-password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className="mb-3"
                      />
                      {errorMsg.length > 0 && <p className="error-msg">{errorMsg}</p>}
                      {successMsg && <p className="success-msg">{successMsg}</p>}
                      <CRow>
                        <CCol xs={6}>
                          <CButton
                            color="primary"
                            style={{ width: '100%' }}
                            onClick={handleChangePassword}
                            loading={loading}
                          >
                            Change password
                          </CButton>
                        </CCol>
                        <CCol xs={6}>
                          <Link to="/login">
                            <CButton style={{ width: '100%', color: 'white' }} color="secondary">
                              Login
                            </CButton>
                          </Link>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}

export default ResetPassword
